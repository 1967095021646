const OKTA_Groups = {
  campaign_manager: 'LCM-Insight_Engine-Dev_Campaign_Manager',
  qa: 'LCM-Insight_Engine-Dev_QA_Tester',
  promoter: 'LCM-Insight_Engine-Test_Dev_Campaign_Promoter',
  drb_user: 'LCM-Insight_Engine-DRB_User',
  drb_manager: 'LCM-Insight_Engine-DRB_Manager'
};
export const environment = {
  production: false,
  appName: 'Insight Engine UI',
  okta_groups: OKTA_Groups,
  ADFS_LOGIN_URL: 'https://fleet-login-external.iot.irobotapi.com/v1/adfs/login',
  ADFS_LOGOUT_URL: 'https://fleet-login.prod.iot.irobotapi.com/v1/adfs/logout',
  envName: 'dev',
  configEndpoint: 'ruhld4ou6i',
  circuitUrl: 'https://dev-b-circuit-auth.test.iot.irobotapi.com/',
  account_num: '400116555502',
  awsRegion: 'us-east-1',
  cookieExpireTime: 60 * 60,
  currentVersion: '@SOURCE_CODE_VERSION@',
  stage: 'dev',
  apiAccountStage: 'dev',
  client_id: 'mpseqhgdp2hn40deb5r314ckn',
  cognitoTokenURL: 'https://insights-dev.auth.us-east-1.amazoncognito.com/oauth2/token',
  loginUrl:
    'https://insight-engine-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=OKTA&redirect_uri=https://engine.app.insights-dev.data.irobotapi.com/callback&response_type=CODE&client_id=mpseqhgdp2hn40deb5r314ckn&scope=email openid profile',
  redirectURL: 'https://engine.app.insights-dev.data.irobotapi.com/callback',
  logout:
    'https://hkc-insights-dev.auth.us-east-1.amazoncognito.com/logout?' +
    'client_id=mpseqhgdp2hn40deb5r314ckn&' +
    'response_type=code&' +
    'scope=openid+email' +
    '&redirect_uri=https://engine.app.insights-dev.data.irobotapi.com/signout',
  bundle_names: ['marketing', 'marketing_translations']
};

export const config = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_r36Iq7Zko',
  aws_user_pools_web_client_id: 'mpseqhgdp2hn40deb5r314ckn',
  aws_cognito_identity_pool_id: 'us-east-1:b5172833-af82-43bd-8156-9dbd88edaa90',
  API: {
    endpoints: [
      {
        name: 'development',
        endpoint: 'https://ruhld4ou6i.execute-api.us-east-1.amazonaws.com',
        region: 'us-east-1'
      }
    ]
  },
  oauth: {
    domain: 'insight-engine-dev.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'https://engine.app.insights-dev.data.irobotapi.com/callback',
    redirectSignOut: 'https://engine.app.insights-dev.data.irobotapi.com/signout',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};
